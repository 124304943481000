<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        x-small
        class="mr-2"
        title="Historial de Requisiciones"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-history</v-icon>
      </v-btn>
    </template>

    <v-card>
      <!-- Header del modal -->
      <v-card-title class="headline primary white--text">
        Historial de Requisiciones
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-row>
          <!-- Sección 1: Información básica del servicio -->
          <v-col cols="12">
            <v-card outlined>
              <v-card-text>
                <div class="text-h6 mb-2">Información del Servicio</div>
                <v-row>
                  <v-col cols="4">
                    <div class="caption grey--text">Servicio</div>
                    <div class="subtitle-1">{{ servicio.egreso_programado }}</div>
                  </v-col>
                  <v-col cols="4">
                    <div class="caption grey--text">Cuenta</div>
                    <div class="subtitle-1">{{ servicio.cuenta }}</div>
                  </v-col>
                  <v-col cols="4">
                    <div class="caption grey--text">Recurrencia</div>
                    <div class="subtitle-1">
                      <v-chip small color="primary" text-color="white">
                        {{ getRecurrenciaText(servicio.recurrencia) }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Sección 2: Tabla de requisiciones -->
          <v-col cols="12">
            <v-card outlined>
              <v-card-text>
                <div class="d-flex align-center mb-4">
                  <div class="text-h6">Historial de Requisiciones</div>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    small
                    @click="generarRequi"
                    :loading="guardando"
                  >
                    <v-icon left small>mdi-plus</v-icon>
                    Generar requisición
                  </v-btn>
                </div>

                <!-- Tabla de historial de requisiciones -->
                <v-data-table
                  :headers="requisicionHeaders"
                  :items="historialRequisiciones"
                  :items-per-page="5"
                  class="elevation-1"
                  :loading="loadingRequisiciones"
                  loading-text="Cargando... Por favor, espere"
                  no-data-text="No hay registros disponibles"
                >
                  <template v-slot:item.fecha_registro="{ item }">
                    {{ item.fecha_registro_formatted }}
                  </template>

                  <template v-slot:item.estatus="{ item }">
                    <v-chip
                      small
                      :color="item.estatus_color"
                      text-color="white"
                    >
                      {{ item.estatus_texto }}
                    </v-chip>
                  </template>

                  <template v-slot:item.monto="{ item }">
                    {{ formatCurrency(item.monto) }}
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      x-small
                      icon
                      color="error"
                      @click="confirmarEliminarRequisicion(item)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Modal para confirmar eliminación de requisición -->
          <v-dialog v-model="dialogConfirmacion" max-width="400px">
            <v-card>
              <v-card-title class="headline">
                Confirmar Eliminación
              </v-card-title>
              <v-card-text>
                ¿Está seguro que desea eliminar esta requisición?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey darken-1"
                  text
                  @click="dialogConfirmacion = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="error"
                  @click="eliminarRequisicion"
                  :loading="eliminando"
                >
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HistorialRequisiciones',

  props: {
    servicio: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    dialogDetalles: false,
    loadingRequisiciones: false,
    guardando: false,
    requisicionSeleccionada: null,
    dialogConfirmacion: false,
    requisicionAEliminar: null,
    eliminando: false,
    historialRequisiciones: [],
    requisicionHeaders: [
      { text: 'Folio', value: 'id', align: 'center' },
      { text: 'Detalle', value: 'comentarios' },
      { text: 'Monto', value: 'monto' },
      { text: 'Fecha', value: 'fecha_registro' },
      { text: 'Estado', value: 'estatus' },
      { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
    ]
  }),

  computed: {
    ...mapGetters('login', ['getdatosUsuario'])
  },

  methods: {
    formatFecha(fecha) {
      return new Date(fecha).toLocaleDateString('es-MX', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },

    async cargarHistorialRequisiciones() {
      this.loadingRequisiciones = true;
      try {
        // Usar el ID correcto del servicio
        const idServicio = this.servicio.id_servicio_sistema || this.servicio.idservicios_sistemas;

        if (!idServicio) {
          console.error("Error: ID de servicio no disponible");
          return;
        }

        // Obtener las requisiciones
        const response = await this.$http.get(`requisiciones.list.by_servicio/${idServicio}`);

        // Mapear los datos
        const requisiciones = response.data.map(req => ({
          id: req.id || req.idrequisicion_compra,
          folio: `REQ-${(req.id || req.idrequisicion_compra).toString().padStart(4, '0')}`,
          fecha_registro: req.fecha_registro || req.fecha_alta,
          fecha_registro_formatted: this.formatFecha(req.fecha_registro || req.fecha_alta),
          comentarios: req.comentarios || '',
          monto: req.monto_solicitado || 0, // Usamos monto_solicitado de servicios_sistemas
          estatus: req.estatus || req.id_requisicion_compra_estatus,
          estatus_texto: this.getEstatusTexto(req.estatus || req.id_requisicion_compra_estatus),
          estatus_color: this.getEstatusColor(req.estatus || req.id_requisicion_compra_estatus)
        }));

        // Ordenar por fecha más reciente primero
        requisiciones.sort((a, b) => new Date(b.fecha_registro) - new Date(a.fecha_registro));

        // Actualizar la lista
        this.historialRequisiciones = requisiciones;
      } catch (error) {
        console.error('Error al cargar historial de requisiciones:', error);
        this.$emit('mostrar-error', 'Error al cargar el historial de requisiciones');
      } finally {
        this.loadingRequisiciones = false;
      }
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
      }).format(value);
    },

    async generarRequi() {
      this.guardando = true;

      try {
        // Obtener el ID correcto del servicio
        const idServicio = this.servicio.id_servicio_sistema || this.servicio.idservicios_sistemas;

        // Payload con los campos necesarios
        const payload = {
          registro: null,
          id_usuario_solicita: this.getdatosUsuario.iderp,
          id_sucursal_solicita: this.getdatosUsuario.id_plantel || this.servicio.id_plantel,
          id_requisicion_compra_estatus: 0,
          id_usuario_ultimo_cambio: this.getdatosUsuario.iderp,
          id_usuario_compra: 28,
          tipo_requisicion: this.servicio.id_tipo_elemento || 1,
          id_ciclo: 345,
          id_proyecto: null,
          idproveedores: this.servicio.proveedor || null,
          idservicios_sistemas: idServicio,
          comentarios: `Pago de Servicio: ${this.servicio.egreso_programado || this.servicio.servicio}`,
          monto: this.servicio.monto_solicitado || 0, // Aseguramos incluir el monto en el payload
          id_elemento_requisicion: 1,
          detalles: [],
          fotos: []
        };

        // Enviar requisición
        const response = await this.$http.post('generar.nomina.operaciones', payload);

        // Extraer el ID de la requisición generada
        const idRequisicion = response.data.idrequisicion_compra;

        if (!idRequisicion) {
          throw new Error("No se pudo generar la requisición correctamente");
        }

        // Agregar la nueva requisición al historial local
        const nuevaRequisicion = {
          id: idRequisicion,
          folio: `REQ-${idRequisicion.toString().padStart(4, '0')}`,
          fecha_registro: new Date().toISOString(),
          fecha_registro_formatted: this.formatFecha(new Date()),
          comentarios: payload.comentarios,
          monto: this.servicio.monto_solicitado || 0, // Aseguramos usar el monto del servicio
          estatus: 0, // estado inicial
          estatus_texto: this.getEstatusTexto(1),
          estatus_color: this.getEstatusColor(1)
        };

        // Agregar al inicio de la lista para que aparezca primero
        this.historialRequisiciones.unshift(nuevaRequisicion);

        // Mostrar mensaje de éxito
        this.$emit('mostrar-exito', `Requisición generada correctamente. ID: ${idRequisicion}`);

        // También refrescar la lista completa desde el servidor
        await this.cargarHistorialRequisiciones();

      } catch (error) {
        console.error('Error al generar requisición:', error);

        const errorMessage = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Error al generar la requisición';

        this.$emit('mostrar-error', errorMessage);
      } finally {
        this.guardando = false;
      }
    },

    // Método para abrir el diálogo de confirmación
    confirmarEliminarRequisicion(item) {
      this.requisicionAEliminar = item;
      this.dialogConfirmacion = true;
    },

    // Método para eliminar la requisición
    async eliminarRequisicion() {
      this.eliminando = true;
      try {
        // Llamar a la API para eliminar la requisición
        await this.$http.delete(`requisiciones.delete/${this.requisicionAEliminar.id}`);

        // Eliminar la requisición de la lista local
        const index = this.historialRequisiciones.findIndex(r => r.id === this.requisicionAEliminar.id);
        if (index !== -1) {
          this.historialRequisiciones.splice(index, 1);
        }

        // Mostrar mensaje de éxito
        this.$emit('mostrar-exito', 'Requisición eliminada correctamente');

        // Cerrar el diálogo de confirmación
        this.dialogConfirmacion = false;
        this.requisicionAEliminar = null;
      } catch (error) {
        console.error('Error al eliminar requisición:', error);

        const errorMessage = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Error al eliminar la requisición';

        this.$emit('mostrar-error', errorMessage);
      } finally {
        this.eliminando = false;
      }
    },

    verDetallesRequisicion(item) {
      this.requisicionSeleccionada = item;
      this.dialogDetalles = true;
    },

    // Obtiene el texto de recurrencia
    getRecurrenciaText(value) {
      const recurrencias = {
        1: "Diario",
        2: "Mensual",
        3: "Bimestral",
        4: "Anual"
      };
      return recurrencias[value] || "";
    },

    // Obtiene el texto del estado
    getEstatusTexto(estatus) {
      const estatusMap = {
        0: 'En proceso',
        1: 'Aprobado',
        2: 'Con evidencia',
        3: 'Rechazado'
      };
      return estatusMap[estatus] || 'Desconocido';
    },

    // Obtiene el color del estado
    getEstatusColor(estatus) {
      const colorMap = {
        0: 'warning',
        1: 'success',
        2: 'info',
        3: 'error'
      };
      return colorMap[estatus] || 'grey';
    }
  },

  mounted() {
    this.cargarHistorialRequisiciones();
  },
  
  watch: {
    dialog(val) {
      if (val) {
        this.cargarHistorialRequisiciones();
      }
    }
  }
}
</script>