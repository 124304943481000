<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <!-- Botones -->
          <v-card-title primary-title>
            <span class="text-subtitle-1">Administracion de Egresos Programados</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(servicios_sistemas, 'servicios_sistemas_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <!-- Tabla de items -->
          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  name="search"
                  label="Buscar"
                  id="search"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="servicios_sistemas"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.recurrencia="{ item }">
                    {{ getRecurrenciaText(item.recurrencia) }}
                  </template>

                  <!-- Columna de estado activo/inactivo -->
                  <template v-slot:item.activo_sn="{ item }">
                    <v-chip
                      v-if="item.activo_sn == 1"
                      small
                      color="green"
                      dark
                    >Si</v-chip>
                    <v-chip
                      v-else
                      small
                      color="error"
                      dark
                    >No</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      x-small
                      @click="editItem(item)"
                      class="mr-2"
                      title="Editar"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      x-small
                      @click="deleteItem(item)"
                      class="mr-2"
                      title="Borrar"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                    <historial-servicio
                      :servicio="item"
                      ref="historialComponent"
                      @actualizar="initialize"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ editedItem.id_servicio_sistema }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            :items="elementos"
            v-model="editedItem.id_elemento_mantenimiento"
            @change="onElementoSeleccionado"
            item-text="elemento_mantenimiento"
            item-value="id_elemento_mantenimiento"
            label="Seleccionar Elemento de compra"
            filled
            dense
          ></v-autocomplete>

          <v-autocomplete
            :items="categorias"
            v-model="editedItem.id_tipo_elemento"
            label="Categoría"
            filled
            dense
            item-text="tipo_requisicion"
            item-value="idtipo_requisiciones"
            @change="getComentariosCategorias()"
          ></v-autocomplete>

          <v-textarea
            v-if="editedIndex !== -1"
            v-model="editedItem.comentarios_categoria"
            label="Comentarios Categoria"
            filled
            rows="3"
            auto-grow
            readonly
          ></v-textarea>

          <v-textarea
            v-if="editedIndex === -1"
            v-model="dato_categoria"
            label="Comentarios Categoria"
            filled
            rows="3"
            auto-grow
            readonly
          ></v-textarea>

          <v-text-field
            v-model="editedItem.cuenta"
            label="Cuenta"
            filled
            dense
          ></v-text-field>

          <v-text-field
            v-model.number="editedItem.monto_solicitado"
            label="Monto"
            type="number"
            min="0"
            step="0.01"
            filled
            dense
          ></v-text-field>

          <v-autocomplete
              filled
              dense
              clearable
              v-model="editedItem.proveedor"
              :items="proveedores"
              label="Selecciona proveedor"
              persistent-hint
              item-text="nombre_comercial"
              item-value="idproveedores"
            >
          </v-autocomplete>

          <v-autocomplete
            v-model="editedItem.id_plantel"
            :items="planteles"
            item-text="plantel"
            item-value="id_plantel"
            label="Plantel"
            filled
            dense
          ></v-autocomplete>

          <v-text-field
            v-model="editedItem.fecha_vencimiento"
            label="Fecha de Vencimiento"
            type="date"
            filled
            dense
          ></v-text-field>

          <v-textarea
            v-model="editedItem.comentarios"
            label="Comentarios"
            filled
            rows="3"
            auto-grow
          ></v-textarea>

          <v-radio-group v-model="editedItem.recurrencia">
            <v-subheader>Recurrencia de pago:</v-subheader>
            <v-radio label="Diario" value="1"></v-radio>
            <v-radio label="Mensual" value="2"></v-radio>
            <v-radio label="Bimestral" value="3"></v-radio>
            <v-radio label="Anual" value="4"></v-radio>
            <v-radio label="Catorcenal" value="5"></v-radio>
            <v-radio label="No Aplica" value="0"></v-radio>
            <v-radio label="Semanal" value="6"></v-radio>
          </v-radio-group>

          <v-switch
            label="¿Activo Si/No?"
            hide-details
            v-model="editedItem.activo_sn"
          ></v-switch>

          <div class="text-subtitle-1"><b>Fecha alta:</b> {{ editedItem.fecha_alta }}</div>
          <div class="text-subtitle-1"><b>Fecha baja:</b> {{ editedItem.fecha_baja }}</div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="black" tile dark small @click="close">
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" tile dark small @click="save">
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el servicio?
                  <v-card-actions class="pt-6">
                    <v-btn color="error" text class="mr-2" @click="closeDelete()">
                      No, cancelar
                    </v-btn>
                    <v-btn color="#5C5C5C" dark class="elevation-6" @click="deleteItemConfirm()">
                      Si
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Alerta from '@/components/alertas/Alerta.vue'
import carga from '@/components/alertas/carga.vue'
import validarErrores from '@/mixins/validarErrores'
import funcionesExcel from '@/mixins/funcionesExcel'
import HistorialServicio from '../../components/HistorialServicio.vue'

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
    HistorialServicio,
  },

  data: () => ({
    parametros: {
      dialogError: false,
      mensaje: '',
      color: ''
    },
    estatus: 10,
    files: [],
    cargar: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    search: '',
    servicios_sistemas: [],
    planteles: [],
    categorias: [],
    info_categoria: '',
    dato_categoria: '',
    elementos: [],
    id_elemento_mantenimiento: null,
    proveedores:[],

    editedItem: {
      id_servicio_sistema: 0,
      servicio: '',
      cuenta: '',
      fecha_vencimiento: '',
      monto_solicitado: 0,
      monto_pagado: 0,
      evidencia: '',
      id_requisicion: '',
      id_plantel: 0,
      recurrencia: 0,
      id_usuario_ultimo_cambio:0,
      id_tipo_elemento: 0,
      comentarios_categoria: '',
      comentarios: '',
      activo_sn: true,
      fecha_alta: '',
      fecha_baja: '',
      deleted: 0,
    },

    defaultItem: {
      id_servicio_sistema: 0,
      servicio: '',
      cuenta: '',
      fecha_vencimiento: '',
      monto_solicitado: 0,
      monto_pagado: 0,
      evidencia: '',
      id_requisicion: '',
      id_plantel: 0,
      recurrencia: 0,
      id_usuario_ultimo_cambio:0,
      id_tipo_elemento: 0,
      comentarios_categoria: '',
      comentarios: '',
      activo_sn: true,
      fecha_alta: '',
      fecha_baja: '',
      deleted: 0,
    },

    headers: [
      { text: "Egreso Programado", value: "egreso_programado" },
      { text: "Plantel", value: "plantel" },
      { text: "Monto", value: "monto_solicitado" },
      { text: "Fecha de Vencimiento", value: "fecha_vencimiento" },
      { text: "Recurrente", value: "recurrencia" },
      { text: "Activo", value: "activo_sn" },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],

  }),

  computed: {
    ...mapGetters('login', ['getdatosUsuario']),
    formTitle() {
      return this.editedIndex === -1 ? 'Agregar Egreso Programado' : 'Editar Egreso Programado'
    },
    serviciosSistemasConRecurrencia() {
      return this.servicios_sistemas.map(servicio => ({
        ...servicio,
        recurrencia_texto: this.getRecurrenciaText(servicio.recurrencia)
      }));
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async created() {
    await this.initialize()
    await this.getPlanteles()
    await this.getCategorias()
    await this.getProveedores();
  },

  methods: {
    async initialize() {
      try {
        const [responseServicios, responseElementos] = await Promise.all([
          this.$http.get('servicios_sistemas.list'),
          this.$http.get('consultar.elemtos.compra')
        ]);

        // Agregar logging para depuración
        console.log('SERVICIOS recibidos del servidor:', responseServicios.data);
        console.log('ELEMENTOS recibidos:', responseElementos.data);

        // Primer servicio para inspección detallada (si existe)
        if (responseServicios.data.length > 0) {
          console.log('DETALLE DEL PRIMER SERVICIO:', JSON.stringify(responseServicios.data[0], null, 2));
        }
      
        this.servicios_sistemas = responseServicios.data.map(item => {
          // Para cada servicio, verificar si tiene un elemento de mantenimiento relacionado
          const elementoEncontrado = this.elementos.find(
            elem => elem.id_elemento_mantenimiento === item.id_elemento_mantenimiento
          );

          console.log(`Servicio ${item.id_servicio_sistema} - Elemento relacionado:`, 
            elementoEncontrado ? elementoEncontrado.id_elemento_mantenimiento : 'No encontrado');

          return {
            ...item,
            activo_sn: item.activo_sn !== undefined ? item.activo_sn : 1,
            // Asegurar que estos campos no se pierdan
            egreso_programado: item.egreso_programado || item.servicio,
            servicio: item.servicio || item.egreso_programado,
            // Asegurar que el proveedor se guarde en la propiedad correcta
            proveedor: item.proveedor || item.idproveedores,
            // Asegurar que id_elemento_mantenimiento esté definido si existe
            id_elemento_mantenimiento: item.id_elemento_mantenimiento
          };
        });
      
        // Almacenar los elementos para autocompletado
        this.elementos = responseElementos.data;
      
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.message 
          ? error.response.data.message 
          : 'Error al cargar los datos';
        this.validarError(errorMessage);
      } finally {
        this.cargar = false;
      }
    },

    onElementoSeleccionado(elemento) {
      // Buscar el elemento completo en el array de elementos
      const elementoSeleccionado = this.elementos.find(
        item => item.id_elemento_mantenimiento === elemento
      );

      if (elementoSeleccionado) {
        // Mapear campos desde Elemento a Egreso Programado
        this.editedItem.servicio = elementoSeleccionado.elemento_mantenimiento;
        this.editedItem.egreso_programado = elementoSeleccionado.elemento_mantenimiento;
        this.editedItem.id_plantel = elementoSeleccionado.id_plantel;
        this.editedItem.id_tipo_elemento = elementoSeleccionado.id_tipo_elemento;
        this.editedItem.comentarios = elementoSeleccionado.comentarios;
        this.editedItem.cuenta = elementoSeleccionado.no_servicio; // Suponiendo que quieres usar el número de servicio como cuenta

        // Si quieres obtener los comentarios de categoría
        if (elementoSeleccionado.id_tipo_elemento) {
          this.getComentariosCategorias();
        }
      }
    },

    getProveedores( ){
      this.proveedores = [];
      this.cargar = true;

      return this.$http.get("proveedores.list.erp").then((response) => {
        this.proveedores = response.data;

        this.cargar = false;

      }).catch((error) => {
          this.validarError(error);
      }).finally(() => {
          this.cargar = false;
      });
    },

    getPlanteles() {
      this.cargar = true
      this.planteles = []
      return this.$http.get('planteles.list.erp').then(response => {
        this.planteles = response.data
        this.cargar = false
      }).catch(error => {
        this.validarError(error.response.data.message)
      }).finally(() => { this.cargar = false })
    },

    getCategorias() {
      this.cargar = true
      this.categorias = []
      return this.$http.get('consultar.requisiciones').then(response => {
        this.categorias = response.data
        this.cargar = false
      }).catch(error => {
        this.validarError(error.response.data.message)
      }).finally(() => { this.cargar = false })
    },

    editItem(item) {
      this.editedIndex = this.servicios_sistemas.indexOf(item);

      // Paso 1: Hacer una copia básica del objeto
      this.editedItem = Object.assign({}, item);

      // Paso 2: Asegurarnos de manejar el campo de elemento de mantenimiento
      // Si no existe en el objeto, buscar si hay un elemento relacionado
      if (!this.editedItem.id_elemento_mantenimiento && this.elementos.length > 0) {
        console.log('Buscando elemento relacionado para el servicio', this.editedItem.id_servicio_sistema);

        // Intenta encontrar un elemento que tenga este servicio
        const elementoRelacionado = this.elementos.find(
          elem => elem.elemento_mantenimiento === this.editedItem.egreso_programado ||
                  elem.elemento_mantenimiento === this.editedItem.servicio
        );

        if (elementoRelacionado) {
          console.log('Elemento encontrado por nombre:', elementoRelacionado);
          this.editedItem.id_elemento_mantenimiento = elementoRelacionado.id_elemento_mantenimiento;
        }
      }

      // Paso 3: Establecer valores predeterminados para campos que podrían faltar
      this.editedItem.comentarios = this.editedItem.comentarios || '';
      this.editedItem.comentarios_categoria = this.editedItem.comentarios_categoria || '';
      this.editedItem.activo_sn = this.editedItem.activo_sn !== undefined ? this.editedItem.activo_sn : true;
      this.editedItem.egreso_programado = this.editedItem.egreso_programado || this.editedItem.servicio;

      // Paso 4: Manejar el campo proveedor correctamente
      if (this.editedItem.proveedor === undefined && this.editedItem.idproveedores) {
        this.editedItem.proveedor = this.editedItem.idproveedores;
      }

      // Paso 5: Si tenemos id_elemento_mantenimiento, seleccionemos ese elemento en el dropdown
      if (this.editedItem.id_elemento_mantenimiento) {
        // Esto permitirá que el autocompletado muestre el elemento seleccionado
        console.log('Estableciendo elemento de mantenimiento:', this.editedItem.id_elemento_mantenimiento);

        // Encuentra el elemento en la lista para mostrarlo correctamente
        const elementoSeleccionado = this.elementos.find(
          elem => elem.id_elemento_mantenimiento === this.editedItem.id_elemento_mantenimiento
        );

        if (elementoSeleccionado) {
          console.log('Información del elemento seleccionado:', elementoSeleccionado);
        } else {
          console.log('Advertencia: No se encontró el elemento con ID', this.editedItem.id_elemento_mantenimiento);
        }
      }

      // Paso 6: Si hay categoría seleccionada, obtener sus comentarios
      if (this.editedItem.id_tipo_elemento) {
        this.getComentariosCategorias();
      }

      // Mostrar información de depuración
      console.log('Datos finales para editar:', this.editedItem);

      this.dialog = true;
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.dato_categoria = ''
      })
    },

    deleteItem(item) {
      this.editedIndex = this.servicios_sistemas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.cargar = true
      try {
        const datos = {
          ...this.editedItem,
          deleted: 1,
          activo_sn: 0
        };

        await this.$http.put(`servicios_sistemas.update.erp/${this.editedItem.id_servicio_sistema}`, datos)
        this.closeDelete()
        await this.initialize()
      } catch (error) {
        this.validarError(error.response.data.message)
      } finally {
        this.cargar = false
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      // Paso 1: Preparar los datos
      // Asegurarse de que egreso_programado tenga valor
      if (!this.editedItem.egreso_programado && this.editedItem.servicio) {
        this.editedItem.egreso_programado = this.editedItem.servicio;
      }
      
      // Asegurarse que servicio tenga valor también
      if (!this.editedItem.servicio && this.editedItem.egreso_programado) {
        this.editedItem.servicio = this.editedItem.egreso_programado;
      }
    
      // Asegurarse de que si hay un proveedor seleccionado se guarde correctamente
      if (this.editedItem.proveedor) {
        this.editedItem.idproveedores = this.editedItem.proveedor;
      }
    
      this.editedItem.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp;
      
      // Verificar si se seleccionó un elemento de mantenimiento
      if (!this.editedItem.id_elemento_mantenimiento && this.editedItem.servicio) {
        // Intentar encontrar el elemento por nombre
        const elementoRelacionado = this.elementos.find(
          elem => elem.elemento_mantenimiento === this.editedItem.servicio
        );
        
        if (elementoRelacionado) {
          this.editedItem.id_elemento_mantenimiento = elementoRelacionado.id_elemento_mantenimiento;
        }
      }
    
      this.cargar = true;
      try {
        // Crear un objeto con TODOS los campos que necesitamos enviar
        const datos = {
          id_servicio_sistema: this.editedItem.id_servicio_sistema,
          servicio: this.editedItem.servicio || this.editedItem.egreso_programado,
          egreso_programado: this.editedItem.egreso_programado || this.editedItem.servicio,
          id_elemento_mantenimiento: this.editedItem.id_elemento_mantenimiento,
          cuenta: this.editedItem.cuenta || '',
          fecha_vencimiento: this.editedItem.fecha_vencimiento || null,
          monto_solicitado: this.editedItem.monto_solicitado || 0,
          monto_pagado: this.editedItem.monto_pagado || 0,
          id_plantel: this.editedItem.id_plantel || null,
          recurrencia: this.editedItem.recurrencia || 0,
          id_tipo_elemento: this.editedItem.id_tipo_elemento || 0,
          comentarios_categoria: this.editedItem.comentarios_categoria || '',
          comentarios: this.editedItem.comentarios || '',
          activo_sn: this.editedItem.activo_sn !== undefined ? this.editedItem.activo_sn : true,
          id_usuario_ultimo_cambio: this.editedItem.id_usuario_ultimo_cambio || 0,
          proveedor: this.editedItem.proveedor || this.editedItem.idproveedores,
          idproveedores: this.editedItem.idproveedores || this.editedItem.proveedor,
          deleted: 0
        };
        
        console.log('Datos a enviar al guardar:', datos);
      
        if (this.editedIndex > -1) {
          // Actualizar
          const response = await this.$http.put(
            `servicios_sistemas.update.erp/${this.editedItem.id_servicio_sistema}`,
            datos
          );
          console.log('Respuesta al actualizar:', response.data);
          this.validarSuccess(response.data.message);
        } else {
          // Agregar
          const response = await this.$http.post('servicios_sistemas.add.erp', datos);
          console.log('Respuesta al agregar:', response.data);
        }
      
        await this.initialize();
        this.close();
      } catch (error) {
        // Manejar errores
        const errorMessage = error.response && error.response.data && error.response.data.message 
          ? error.response.data.message 
          : 'Error al guardar';
        console.error('Error al guardar:', error);
        this.validarError(errorMessage);
      } finally {
        this.cargar = false;
      }
    },

    getComentariosCategorias() {
      this.cargar = true
      this.info_categoria = ''
      this.dato_categoria = ''

      const payload = {
        idtipo_requisiciones: this.editedItem.id_tipo_elemento,
      }

      return this.$http.post('get.info.categoria', payload).then(response => {
        this.info_categoria = response.data
        this.dato_categoria = this.info_categoria.comentarios
        this.editedItem.comentarios_categoria = this.dato_categoria
        this.cargar = false
      }).catch(error => {
        this.validarError(error.response.data.message)
      }).finally(() => { this.cargar = false })
    },

    getRecurrenciaText(value) {
      const recurrencias = {
        0: "No Aplica",
        1: "Diario",
        2: "Mensual",
        3: "Bimestral",
        4: "Anual",
        5: "Catorcenal",
        6: "Semanal"
      };
      return recurrencias[value] || "";
    },

    verHistorial(item) {
      console.log(`Mostrando historial de ${item.servicio}`);
    },

  }
}
</script>
